<template>
  <div classs="grand">
    <div class="bluehead">
      <div class="row">
        <div style="text-align: center" class="col">
          <h5>{{ h1 }}</h5>
        </div>
        <div class="btn-menu">
          <div
            @click="
              exportationVCF = false;
              exportation = false;
              pasDeTelephone = false
            "
            class="btn rounded-circle p-0 mx-1"
            style="background-color: #0000fe"
          >
            <!--svg
            data-bs-toggle="modal"
            data-bs-target="#staticBackdrop2"
            style="color: white"
              xmlns="http://www.w3.org/2000/svg"
              width="14"
              height="14"
              fill="white"
              class="bi bi-download icone-sup"
              viewBox="0 0 16 16"
            >
              <path
                d="M.5 9.9a.5.5 0 0 1 .5.5v2.5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-2.5a.5.5 0 0 1 1 0v2.5a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2v-2.5a.5.5 0 0 1 .5-.5z"
              />
              <path
                d="M7.646 11.854a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 10.293V1.5a.5.5 0 0 0-1 0v8.793L5.354 8.146a.5.5 0 1 0-.708.708l3 3z"
              />
            </!--svg-->
            <svg
              data-bs-toggle="modal"
              data-bs-target="#staticBackdrop2"
              style="color: white"
              xmlns="http://www.w3.org/2000/svg"
              width="14"
              height="14"
              fill="white"
              class="bi bi-arrow-down icone-sup"
              viewBox="0 0 16 16"
            >
              <path
                fill-rule="evenodd"
                d="M8 1a.5.5 0 0 1 .5.5v11.793l3.146-3.147a.5.5 0 0 1 .708.708l-4 4a.5.5 0 0 1-.708 0l-4-4a.5.5 0 0 1 .708-.708L7.5 13.293V1.5A.5.5 0 0 1 8 1z"
              />
            </svg>
          </div>
        </div>
        <a class="btn-menu" href="">
          <router-link
            class="nav-link routlink rounded-circle p-0 mx-1"
            style="background-color: #0000fe"
            :to="`/Membres/${0} `"
          >
            <svg
              style="color: white"
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              fill="white"
              class="bi icone-sup bi-person-plus-fill"
              viewBox="0 0 16 16"
            >
              <path
                d="M1 14s-1 0-1-1 1-4 6-4 6 3 6 4-1 1-1 1H1zm5-6a3 3 0 1 0 0-6 3 3 0 0 0 0 6z"
              />
              <path
                fill-rule="evenodd"
                d="M13.5 5a.5.5 0 0 1 .5.5V7h1.5a.5.5 0 0 1 0 1H14v1.5a.5.5 0 0 1-1 0V8h-1.5a.5.5 0 0 1 0-1H13V5.5a.5.5 0 0 1 .5-.5z"
              />
            </svg>
          </router-link>
        </a>
        <a v-if="typeCompte == 'Super Admin'" class="btn-menu" href="">
          <router-link
            class="nav-link routlink rounded-circle p-0 mx-1"
            style="background-color: #0000fe"
            :to="`/Membres/${modification} `"
          >
            <svg
              style="color: white"
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              fill="white"
              class="bi bi-pencil-fill icone-sup"
              viewBox="0 0 16 16"
            >
              <path
                d="M12.854.146a.5.5 0 0 0-.707 0L10.5 1.793 14.207 5.5l1.647-1.646a.5.5 0 0 0 0-.708l-3-3zm.646 6.061L9.793 2.5 3.293 9H3.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.207l6.5-6.5zm-7.468 7.468A.5.5 0 0 1 6 13.5V13h-.5a.5.5 0 0 1-.5-.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.5-.5V10h-.5a.499.499 0 0 1-.175-.032l-.179.178a.5.5 0 0 0-.11.168l-2 5a.5.5 0 0 0 .65.65l5-2a.5.5 0 0 0 .168-.11l.178-.178z"
              />
            </svg>
          </router-link>
        </a>
      </div>
    </div>
    <center v-if="echec">
      <div>Probleme de connexion.Reéssayer svp</div>
      <svg
        @click="
          echec = '0';
          recharger();
        "
        xmlns="http://www.w3.org/2000/svg"
        width="20"
        height="20"
        style="color: blue; cursor: pointer"
        fill="currentColor"
        class="bi bi-bootstrap-reboot"
        viewBox="0 0 16 16"
      >
        <path
          d="M1.161 8a6.84 6.84 0 1 0 6.842-6.84.58.58 0 1 1 0-1.16 8 8 0 1 1-6.556 3.412l-.663-.577a.58.58 0 0 1 .227-.997l2.52-.69a.58.58 0 0 1 .728.633l-.332 2.592a.58.58 0 0 1-.956.364l-.643-.56A6.812 6.812 0 0 0 1.16 8tableau"
        />
        <path
          d="M6.641 11.671V8.843h1.57l1.498 2.828h1.314L9.377 8.665c.897-.3 1.427-1.106 1.427-2.1 0-1.37-.943-2.246-2.456-2.246H5.5v7.352h1.141tableaum0-3.75V5.277h1.57c.881 0 1.416.499 1.416 1.32 0 .84-.504 1.324-1.386 1.324h-1.6tableau"
        />
      </svg>
      <div
        v-if="echec == '0'"
        class="spinner-border text-primary"
        role="status"
      ></div>
    </center>

    <section v-else class="liste text-center container-lg box-center">
      <div v-if="!noresult" class="row">
        <div class="col-3 xorder2 order-2">
          <div v-if="typeCompte == 'Super Admin'" class="d-md-flex mb-2">
            <div class="custom-control custom-switch">
              <input
                type="checkbox"
                v-model="elementEnAttente"
                class="custom-control-input"
                id="item"
              />
              <label class="custom-control-label p-1" for="item">
                En attente de validation
              </label>
            </div>
            <!--div class="custom-control custom-switch">
              <input
                type="checkbox"
                v-model="elementActive"
                class="custom-control-input"
                id="item2"
              />
              <label class="custom-control-label" for="item2">
                Membre(s) validé(s)
              </label>
            </!--div-->
          </div>
          <div class="search">
            <span style="display: flex">
              <input
                id="recherche"
                v-model="search"
                type="search"
                class="form-control"
                placeholder="Rechercher..."
                aria-describedby="passwordHelpBlock"
              />
              <!--div @click="recH()" class="btn btn-primary">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="currentColor"
                  class="bi bi-search"
                  viewBox="0 0 16 16"
                >
                  <path
                    d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z"
                  />
                </svg>
              </!--div-->
            </span>
          </div>
          <div class="mt-2">
            Nombre de résultat(s): {{ filteredMembre.length }}
          </div>

          <hr />

          <div
            v-if="chargement"
            class="spinner-border text-primary"
            style="width: 2rem; height: 2rem"
            role="status"
          >
            <span class="visually-hidden"></span>
          </div>
          <div id="documents" class="list-scrool documents">
            <div
              style="text-align: start"
              v-for="item in filteredMembre"
              :key="item.index"
              class="border blanc"
            >
              <div
                @click="
                  (selectitem = item),
                    (selectitem2 = item),
                    (affiche = true),
                    exporteur(item),
                    (erreur = false)
                "
                class="btn pointille text-left px-0 document col-xl-11 col-10"
                style="width: 100%; height: 100%"
              >
                <img
                  style="margin: 10px; border-radius: 100%"
                  v-if="item.lien_photo"
                  :src="photo + item.lien_photo"
                  alt="Photo"
                  width="50"
                  height="50"
                  srcset=""
                  class="image-fit"
                />
                <svg
                  style="margin: 10px"
                  v-else
                  xmlns="http://www.w3.org/2000/svg"
                  width="50"
                  height="50"
                  fill="currentColor"
                  class="bi color-icone bi-person-circle"
                  viewBox="0 0 16 16"
                >
                  <path d="M11 6a3 3 0 1 1-6 0 3 3 0 0 1 6 0z" />
                  <path
                    fill-rule="evenodd"
                    d="M0 8a8 8 0 1 1 16 0A8 8 0 0 1 0 8zm8-7a7 7 0 0 0-5.468 11.37C3.242 11.226 4.805 10 8 10s4.757 1.225 5.468 2.37A7 7 0 0 0 8 1z"
                  />
                </svg>
                <span class="text-truncate"
                  >{{ item.prenom }} {{ item.nom.toUpperCase() }}</span
                >
              </div>
              <span
                class="col-md-1 col-2 bg-dark align-self-start text-white rounded-circle"
                style="font-size: 9px; padding: 3px 4px"
              >
                {{ item.id }}
              </span>
            </div>
            <br />
            <hr />
          </div>
        </div>
        <div v-if="chargement" class="col page-vide order-1">
          <div class="spinner-grow text-info" role="status">
            <span class="sr-only">Loading...</span>
          </div>
          <div class="spinner-grow text-info" role="status">
            <span class="sr-only">Loading...</span>
          </div>
          <div class="spinner-grow text-info" role="status">
            <span class="sr-only">Loading...</span>
          </div>
          <p>chargement des données...</p>
        </div>
        <div v-if="chargement == false" class="col xorder1 order-1">
          <div
            style="text-align: center"
            v-if="erreur"
            class="alert alert-danger"
            role="alert"
          >
            {{ msg }}
          </div>
          <div
            style="text-align: center"
            v-else-if="msg2 !== ''"
            class="alert alert-success"
            role="alert"
          >
            {{ msg2 }}
          </div>
          <section class="details">
            <div v-if="typeCompte == 'Super Admin'" class="delete float-right">
              <svg
                data-bs-toggle="modal"
                data-bs-target="#staticBackdrop1"
                xmlns="http://www.w3.org/2000/svg"
                width="18"
                height="18"
                fill="currentColor"
                class="bi icone-sup bi-trash-fill"
                viewBox="0 0 16 16"
              >
                <path
                  d="M2.5 1a1 1 0 0 0-1 1v1a1 1 0 0 0 1 1H3v9a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2V4h.5a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1H10a1 1 0 0 0-1-1H7a1 1 0 0 0-1 1H2.5zm3 
                  4a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-1 0v-7a.5.5 0 0 1 .5-.5zM8 5a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-1 0v-7A.5.5 0 0 1 8 5zm3 .5v7a.5.5 0 0 1-1 0v-7a.5.5 0 0 1 1 0z"
                />
              </svg>
            </div>
            <div class="row">
              <div class="col membreprofile">
                <center>
                  <img
                    class="profile border image-fit"
                    v-if="selectitem.lien_photo"
                    :src="photo + selectitem.lien_photo"
                    alt="Photo"
                    width="100"
                    height="100"
                    srcset=""
                  />
                  <div v-else class="profile1 border">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="190"
                      height="190"
                      fill="currentColor"
                      class="bi color-icone bi-person-circle"
                      viewBox="0 0 16 16"
                    >
                      <path d="M11 6a3 3 0 1 1-6 0 3 3 0 0 1 6 0z" />
                      <path
                        fill-rule="evenodd"
                        d="M0 8a8 8 0 1 1 16 0A8 8 0 0 1 0 8zm8-7a7 7 0 0 0-5.468 11.37C3.242 11.226 4.805 10 8 10s4.757 1.225 5.468 2.37A7 7 0 0 0 8 1z"
                      />
                    </svg>
                  </div>
                  <li class="list-item">
                    <span>
                      <a target="_blank" :href="Mail + selectitem.mail"
                        ><img
                          width="15"
                          height="15"
                          src="../assets/png/023-email.png"
                          alt=""
                      /></a>
                      <a target="_blank" :href="Lien + selectitem.facebook"
                        ><img
                          width="15"
                          height="15"
                          src="../assets/png/019-facebook-1.png"
                          alt=""
                      /></a>
                      <a target="_blank" :href="Lienw + selectitem.whatsapp"
                        ><img
                          width="15"
                          height="15"
                          src="../assets/png/021-whatsapp.png"
                          alt=""
                      /></a>
                      <a target="_blank" :href="Lien + selectitem.linkedin"
                        ><img
                          width="15"
                          height="15"
                          src="../assets/png/022-linkedin.png"
                          alt=""
                      /></a>
                    </span>
                  </li>
                </center>
              </div>
              <div class="col">
                <span v-if="selectitem.matricule == ''">
                  Matricule: <span class="element"> Non Fourni </span>
                </span>
                <span v-else>
                  Matricule:
                  <span class="element">
                    {{ selectitem.matricule }}
                  </span> </span
                ><br />

                <span v-if="selectitem.nom == ''">
                  Nom: <span class="element"> Non Fourni </span>
                </span>
                <span v-else>
                  Nom:
                  <span class="element"> {{ selectitem.nom }} </span> </span
                ><br />

                <span v-if="selectitem.prenom == ''">
                  Prénom: <span class="element"> Non Fourni </span>
                </span>
                <span v-else>
                  Prénom:
                  <span class="element"> {{ selectitem.prenom }} </span> </span
                ><br />

                <span v-if="selectitem.date_naissance == ''">
                  Date Naissance: <span class="element"> Non Fourni </span>
                </span>
                <span v-else>
                  Date Naissance:
                  <span class="element">
                    {{ selectitem.date_naissance }}
                  </span> </span
                ><br />

                <span v-if="selectitem.domaine_d_intervention == ''">
                  Domaine: <span class="element"> Non Fourni </span>
                </span>
                <span v-else>
                  Domaine:
                  <span class="element">
                    {{ selectitem.domaine_d_intervention }}
                  </span> </span
                ><br />

                <span v-if="selectitem.profession == ''">
                  Profession: <span class="element"> Non Fourni </span>
                </span>
                <span v-else>
                  Profession:
                  <span class="element">
                    {{ selectitem.profession }}
                  </span> </span
                ><br />

                <span v-if="selectitem.categorie == ''">
                  Catégorie: <span class="element"> Non Fourni </span>
                </span>
                <span v-else>
                  Catégorie:
                  <span class="element">
                    {{ selectitem.categorie }}
                  </span> </span
                ><br />

                <span v-if="selectitem.bureau == ''">
                  Bureau: <span class="element"> Non Fourni </span>
                </span>
                <span v-else>
                  Bureau:
                  <span class="element"> {{ selectitem.bureau }} </span> </span
                ><br />

                <span v-if="selectitem.fonction == ''">
                  Fonction: <span class="element"> Non Fourni </span>
                </span>
                <span v-else>
                  Fonction:
                  <span class="element">
                    {{ selectitem.fonction }}
                  </span> </span
                ><br />

                <span v-if="selectitem.date_adhesion == ''">
                  Membre depuis: <span class="element"> Non Fourni </span>
                </span>
                <span v-else>
                  Membre depuis:
                  <span class="element">
                    {{ selectitem.date_adhesion }}
                  </span> </span
                ><br />

                <span v-if="selectitem.adresse == ''">
                  Adresse: <span class="element"> Non Fourni </span>
                </span>
                <span v-else>
                  Adresse:
                  <span class="element"> {{ selectitem.adresse }} </span> </span
                ><br />

                <span v-if="selectitem.localite == ''">
                  Localité: <span class="element"> Non Fourni </span>
                </span>
                <span v-else>
                  Localité:
                  <span class="element">
                    {{ selectitem.localite }}
                  </span> </span
                ><br />

                <span v-if="selectitem.zone == ''">
                  Zone: <span class="element"> Non Fourni </span>
                </span>
                <span v-else>
                  Zone: <span class="element"> {{ selectitem.zone }} </span>
                </span>
              </div>
            </div>
          </section>
          <div class="sous-titre" v-if="selectitem.infos_organisation !== null">
            <hr />
            ORGANISATION DE PROVENANCE
          </div>
          <section class="membreTab org-info">
            <div
              v-if="selectitem.infos_organisation !== null"
              class="container row"
            >
              <div style="text-align: center" class="col">
                <center>
                  <img
                    v-if="selectitem.infos_organisation.lien_photo !== ''"
                    width="120"
                    height="120"
                    class="imageOrg image-fit"
                    :src="photo + selectitem.infos_organisation.lien_photo"
                    alt=""
                  />
                  <div v-else class="logodansmembre border">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="120"
                      height="120"
                      fill="currentColor"
                      class="bi bi-card-image"
                      viewBox="0 0 16 16"
                    >
                      <path
                        d="M6.002 5.5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0z"
                      />
                      <path
                        d="M1.5 2A1.5 1.5 0 0 0 0 3.5v9A1.5 1.5 0 0 0 1.5 14h13a1.5 1.5 0 0 0 1.5-1.5v-9A1.5 1.5 0 0 0 14.5 2h-13zm13 1a.5.5 0 0 1 .5.5v6l-3.775-1.947a.5.5 0 0 0-.577.093l-3.71 3.71-2.66-1.772a.5.5 0 0 0-.63.062L1.002 12v.54A.505.505 0 0 1 1 12.5v-9a.5.5 0 0 1 .5-.5h13z"
                      />
                    </svg>
                  </div>
                </center>
                <span style="">
                  <div
                    class="titrelien"
                    @click="
                      exporteurID(
                        selectitem.infos_organisation.id,
                        (chemin = 'organisation')
                      )
                    "
                    v-if="selectitem.infos_organisation.nom"
                    style="font-weight: bold; color: black"
                  >
                    {{ selectitem.infos_organisation.nom }}
                  </div>
                  <br />
                  {{ selectitem.infos_organisation.domaine_intervention }}
                </span>
              </div>
              <div class="col org-info">
                <div>
                  <li
                    v-if="selectitem.infos_organisation.adresse"
                    class="list-item pb-1"
                  >
                    <img
                      width="15"
                      height="15"
                      src="../assets/png/map.png"
                      alt=""
                    />
                    {{ selectitem.infos_organisation.adresse }}
                  </li>
                  <li
                    v-if="selectitem.infos_organisation.telephone"
                    class="list-item py-1"
                  >
                    <img
                      width="15"
                      height="15"
                      src="../assets/png/025-phone-call.png"
                      alt=""
                    /><a :href="Tel + selectitem.infos_organisation.telephone">
                      {{ selectitem.infos_organisation.telephone }}
                    </a>
                  </li>
                  <li
                    v-if="selectitem.infos_organisation.mail"
                    class="list-item py-1"
                  >
                    <img
                      width="15"
                      height="15"
                      src="../assets/png/023-email.png"
                      alt=""
                    /><a :href="Mail + selectitem.infos_organisation.mail">{{
                      selectitem.infos_organisation.mail
                    }}</a>
                  </li>
                  <li
                    v-if="selectitem.infos_organisation.facebook"
                    class="list-item py-1"
                  >
                    <img
                      width="15"
                      height="15"
                      src="../assets/png/019-facebook-1.png"
                      alt=""
                    /><a
                      :href="Lien + selectitem.infos_organisation.facebook"
                      >{{ selectitem.infos_organisation.facebook }}</a
                    >
                  </li>
                  <li
                    v-if="selectitem.infos_organisation.whatsapp"
                    class="list-item py-1"
                  >
                    <img
                      width="15"
                      height="15"
                      src="../assets/png/021-whatsapp.png"
                      alt=""
                    /><a
                      :href="Lienw + selectitem.infos_organisation.whatsapp"
                      >{{ selectitem.infos_organisation.whatsapp }}</a
                    >
                  </li>
                  <li
                    v-if="selectitem.infos_organisation.site_web"
                    class="list-item py-1"
                  >
                    <img
                      width="15"
                      height="15"
                      src="../assets/png/026-web.png"
                      alt=""
                    /><a :href="selectitem.infos_organisation.site_web">{{
                      selectitem.infos_organisation.site_web
                    }}</a>
                  </li>
                </div>
              </div>
            </div>
          </section>
          <div
            class="sous-titre"
            v-if="
              selectitem.events.length !== 0 &&
              selectitem.events.infos_event !== null
            "
          >
            <hr />
            EVENEMENTS ASSISTES
          </div>
          <section
            v-if="
              selectitem.events.length !== 0 &&
              selectitem.events.infos_event !== null
            "
            class="membreTab org-info"
          >
            <div
              v-if="selectitem.events.infos_event !== null"
              class="container"
            >
              <div
                v-for="item2 in selectitem.events"
                :key="item2.index"
                class=""
              >
                <span class="shadow-sms-lg">
                  <div style="">
                    <div
                      @click="
                        exporteurID(item2.infos_event.id, (chemin = 'event'))
                      "
                      class="titrelien"
                      style="font-weight: bold; color: black"
                    >
                      {{ item2.infos_event.titre }}
                    </div>
                    <div>{{ item2.infos_event.description }}</div>
                  </div>
                  <div class="row">
                    <span class="list-item x-small">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        fill="currentColor"
                        class="bi icone-bleue bi-calendar-event"
                        viewBox="0 0 16 16"
                      >
                        <path
                          d="M11 6.5a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5v-1z"
                        />
                        <path
                          d="M3.5 0a.5.5 0 0 1 .5.5V1h8V.5a.5.5 0 0 1 1 0V1h1a2 2 0 0 1 2 2v11a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V3a2 2 0 0 1 2-2h1V.5a.5.5 0 0 1 .5-.5zM1 4v10a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V4H1z"
                        />
                      </svg>
                      {{ item2.infos_event.date_debut }} </span
                    >&nbsp; &nbsp;
                    <li class="list-item x-small">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        fill="currentColor"
                        class="bi icone-bleue bi-geo-alt-fill"
                        viewBox="0 0 16 16"
                      >
                        <path
                          d="M8 16s6-5.686 6-10A6 6 0 0 0 2 6c0 4.314 6 10 6 10zm0-7a3 3 0 1 1 0-6 3 3 0 0 1 0 6z"
                        />
                      </svg>
                      {{ item2.infos_event.lieu }}
                    </li>
                  </div>
                  <div></div>
                  <hr />
                </span>
              </div>
            </div>
          </section>
          <div
            class="sous-titre"
            v-if="
              selectitem.formations.length !== 0 &&
              selectitem.formations.infos_formation !== null
            "
          >
            FORMATIONS ASSISTEES
          </div>
          <section
            v-if="
              selectitem.formations.length !== 0 &&
              selectitem.formations.infos_formation !== null
            "
            class="membreTab org-info"
          >
            <div class="container">
              <div
                v-for="item3 in selectitem.formations"
                :key="item3.index"
                class=""
              >
                <span v-if="item3.infos_formation" class="shadow-sms-lg">
                  <div style="">
                    <div
                      @click="
                        exporteurID(
                          item3.infos_formation.id,
                          (chemin = 'formation')
                        )
                      "
                      v-if="item3.infos_formation !== null"
                      class="titrelien"
                      style="font-weight: bold; color: black"
                    >
                      {{ item3.infos_formation.titre }}
                    </div>
                    <div v-if="item3.infos_formation !== null">
                      {{ item3.infos_formation.description }}
                    </div>
                  </div>
                  <div v-if="item3.infos_formation !== null" class="row">
                    <li class="list-item x-small">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        fill="currentColor"
                        class="bi icone-bleue bi-calendar-event"
                        viewBox="0 0 16 16"
                      >
                        <path
                          d="M11 6.5a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5v-1z"
                        />
                        <path
                          d="M3.5 0a.5.5 0 0 1 .5.5V1h8V.5a.5.5 0 0 1 1 0V1h1a2 2 0 0 1 2 2v11a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V3a2 2 0 0 1 2-2h1V.5a.5.5 0 0 1 .5-.5zM1 4v10a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V4H1z"
                        />
                      </svg>
                      {{ item3.infos_formation.date_debut }}
                    </li>
                    &nbsp; &nbsp;
                    <li class="list-item x-small">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        fill="currentColor"
                        class="bi icone-bleue bi-geo-alt-fill"
                        viewBox="0 0 16 16"
                      >
                        <path
                          d="M8 16s6-5.686 6-10A6 6 0 0 0 2 6c0 4.314 6 10 6 10zm0-7a3 3 0 1 1 0-6 3 3 0 0 1 0 6z"
                        />
                      </svg>
                      {{ item3.infos_formation.lieu }}
                    </li>
                  </div>
                  <div v-if="item3.infos_formation !== null">
                    {{ item3.infos_formation.restitution }}
                  </div>
                  <hr />
                </span>
              </div>
            </div>
          </section>
        </div>
      </div>
      <div v-else class="pasdelement">
        <div class="search">
          <span style="display: flex">
            <input
              id="recherche"
              v-model="search"
              type="search"
              class="form-control"
              placeholder="Rechercher..."
              aria-describedby="passwordHelpBlock"
            />
            <div @click="recH()" class="btn btn-primary">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="currentColor"
                class="bi bi-search"
                viewBox="0 0 16 16"
              >
                <path
                  d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z"
                />
              </svg>
            </div>
          </span>
        </div>
        <hr />
        <h4>Aucun membre trouvé</h4>
      </div>
      <!-- Modal Exportation PDF-->
      <div
        class="modal fade"
        id="staticBackdrop3"
        tabindex="-1"
        aria-labelledby="staticBackdropLabel"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        aria-hidden="true"
      >
        <div class="modal-dialog modal-dialog-scrollable">
          <div class="modal-content">
            <div class="modal-header bg-primary">
              <div class="modal-title" id="staticBackdropLabel">
                Veuillez côcher les champs que vous souhaitez avoir dans votre
                tableau
              </div>
              <svg
                type="button"
                data-bs-dismiss="modal"
                aria-label="Close"
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="20"
                fill="currentColor"
                class="bi bi-x"
                viewBox="0 0 16 16"
              >
                <path
                  d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"
                />
              </svg>
            </div>
            <div class="modal-body modaltext">
              <div v-if="!exportation">
              <div class="d-flex">
                <div class="col-6 text-left my-2">
                  <input
                    type="checkbox"
                    v-model="idChecked"
                    class=""
                    id="idChecked"
                  />
                  <label
                    class="col-form-label label-checkbox pl-2 py-0"
                    for="idChecked"
                  >
                    Id
                  </label>
                </div>
                <div class="col-6 text-left my-2">
                  <input
                    type="checkbox"
                    v-model="matriculeChecked"
                    class=""
                    id="matriculeChecked"
                  />
                  <label
                    class="col-form-label label-checkbox pl-2 py-0"
                    for="matriculeChecked"
                  >
                    Matricule
                  </label>
                </div>
              </div>
              <div class="d-flex">
                <div class="col-6 text-left my-2">
                  <input
                    type="checkbox"
                    v-model="nomChecked"
                    class=""
                    id="nomChecked"
                  />
                  <label
                    class="col-form-label label-checkbox pl-2 py-0"
                    for="nomChecked"
                  >
                    Nom
                  </label>
                </div>
                <div class="col-6 text-left my-2">
                  <input
                    type="checkbox"
                    v-model="prenomChecked"
                    class=""
                    id="prenomChecked"
                  />
                  <label
                    class="col-form-label label-checkbox pl-2 py-0"
                    for="prenomChecked"
                  >
                    Prénom
                  </label>
                </div>
              </div>
              <div class="d-flex">
                <div class="col-6 text-left my-2">
                  <input
                    type="checkbox"
                    v-model="genreChecked"
                    class=""
                    id="genreChecked"
                  />
                  <label
                    class="col-form-label label-checkbox pl-2 py-0"
                    for="genreChecked"
                  >
                    Genre
                  </label>
                </div>
                <div class="col-6 text-left my-2">
                  <input
                    type="checkbox"
                    v-model="naissanceChecked"
                    class=""
                    id="naissanceChecked"
                  />
                  <label
                    class="col-form-label label-checkbox pl-2 py-0"
                    for="naissanceChecked"
                  >
                    Date de Naissance
                  </label>
                </div>
              </div>
              <div class="d-flex">
                <div class="col-6 text-left my-2">
                  <input
                    type="checkbox"
                    v-model="fonctionChecked"
                    class=""
                    id="fonctionChecked"
                  />
                  <label
                    class="col-form-label label-checkbox pl-2 py-0"
                    for="fonctionChecked"
                  >
                    Fonction
                  </label>
                </div>
                <div class="col-6 text-left my-2">
                  <input
                    type="checkbox"
                    v-model="professionChecked"
                    class=""
                    id="professionChecked"
                  />
                  <label
                    class="col-form-label label-checkbox pl-2 py-0"
                    for="professionChecked"
                  >
                    Profession
                  </label>
                </div>
              </div>
              <div class="d-flex">
                <div class="col-6 text-left my-2">
                  <input
                    type="checkbox"
                    v-model="domaineChecked"
                    class=""
                    id="domaineChecked"
                  />
                  <label
                    class="col-form-label label-checkbox pl-2 py-0"
                    for="domaineChecked"
                  >
                    Domaine
                  </label>
                </div>
                <div class="col-6 text-left my-2">
                  <input
                    type="checkbox"
                    v-model="bureauChecked"
                    class=""
                    id="bureauChecked"
                  />
                  <label
                    class="col-form-label label-checkbox pl-2 py-0"
                    for="bureauChecked"
                  >
                    Bureau
                  </label>
                </div>
              </div>
              <div class="d-flex">
                <div class="col-6 text-left my-2">
                  <input
                    type="checkbox"
                    v-model="categorieChecked"
                    class=""
                    id="categorieChecked"
                  />
                  <label
                    class="col-form-label label-checkbox pl-2 py-0"
                    for="categorieChecked"
                  >
                    Catégorie
                  </label>
                </div>
                <div class="col-6 text-left my-2">
                  <input
                    type="checkbox"
                    v-model="dateAdhesionChecked"
                    class=""
                    id="dateAdhesionChecked"
                  />
                  <label
                    class="col-form-label label-checkbox pl-2 py-0"
                    for="dateAdhesionChecked"
                  >
                    Date d'adhésion
                  </label>
                </div>
              </div>
              <div class="d-flex">
                <div class="col-6 text-left my-2">
                  <input
                    type="checkbox"
                    v-model="paysChecked"
                    class=""
                    id="paysChecked"
                  />
                  <label
                    class="col-form-label label-checkbox pl-2 py-0"
                    for="paysChecked"
                  >
                    Pays
                  </label>
                </div>
                <div class="col-6 text-left my-2">
                  <input
                    type="checkbox"
                    v-model="villeChecked"
                    class=""
                    id="villeChecked"
                  />
                  <label
                    class="col-form-label label-checkbox pl-2 py-0"
                    for="villeChecked"
                  >
                    Ville
                  </label>
                </div>
              </div>
              <div class="d-flex">
                <div class="col-6 text-left my-2">
                  <input
                    type="checkbox"
                    v-model="localiteChecked"
                    class=""
                    id="localiteChecked"
                  />
                  <label
                    class="col-form-label label-checkbox pl-2 py-0"
                    for="localiteChecked"
                  >
                    Localité
                  </label>
                </div>
                <div class="col-6 text-left my-2">
                  <input
                    type="checkbox"
                    v-model="zoneChecked"
                    class=""
                    id="zoneChecked"
                  />
                  <label
                    class="col-form-label label-checkbox pl-2 py-0"
                    for="zoneChecked"
                  >
                    Zone
                  </label>
                </div>
              </div>
              <div class="d-flex">
                <div class="col-6 text-left my-2">
                  <input
                    type="checkbox"
                    v-model="telephoneChecked"
                    class=""
                    id="telephoneChecked"
                  />
                  <label
                    class="col-form-label label-checkbox pl-2 py-0"
                    for="telephoneChecked"
                  >
                    Téléphone
                  </label>
                </div>
                <div class="col-6 text-left my-2">
                  <input
                    type="checkbox"
                    v-model="whatsappChecked"
                    class=""
                    id="whatsappChecked"
                  />
                  <label
                    class="col-form-label label-checkbox pl-2 py-0"
                    for="whatsappChecked"
                  >
                    WhatsApp
                  </label>
                </div>
              </div>
              <div class="d-flex">
                <div class="col-6 text-left my-2">
                  <input
                    type="checkbox"
                    v-model="mailChecked"
                    class=""
                    id="mailChecked"
                  />
                  <label
                    class="col-form-label label-checkbox pl-2 py-0"
                    for="mailChecked"
                  >
                    Mail
                  </label>
                </div>
                <div class="col-6 text-left my-2">
                  <input
                    type="checkbox"
                    v-model="facebookChecked"
                    class=""
                    id="facebookChecked"
                  />
                  <label
                    class="col-form-label label-checkbox pl-2 py-0"
                    for="facebookChecked"
                  >
                    Facebook
                  </label>
                </div>
              </div>
              <div class="d-flex">
                <div class="col-6 text-left my-2">
                  <input
                    type="checkbox"
                    v-model="linkedinChecked"
                    class=""
                    id="linkedinChecked"
                  />
                  <label
                    class="col-form-label label-checkbox pl-2 py-0"
                    for="linkedinChecked"
                  >
                    Linkedin
                  </label>
                </div>
                <div class="col-6 text-left my-2">
                  <input
                    type="checkbox"
                    v-model="skypeChecked"
                    class=""
                    id="skypeChecked"
                  />
                  <label
                    class="col-form-label label-checkbox pl-2 py-0"
                    for="skypeChecked"
                  >
                    Skype
                  </label>
                </div>
              </div>
              <div class="col-6 text-left my-2">
                <input
                  type="checkbox"
                  v-model="date_enregistrementChecked"
                  class=""
                  id="date_enregistrementChecked"
                />
                <label
                  class="col-form-label label-checkbox pl-2 py-0"
                  for="date_enregistrementChecked"
                >
                  Date d'Enregistrement
                </label>
              </div>
              </div>
              <!--div v-if="supprimer == false" class="row">
                <div style="text-align: center" class="col">
                  <svg
                    style="color: red"
                    xmlns="http://www.w3.org/2000/svg"
                    width="50"
                    height="50"
                    fill="currentColor"
                    class="bi bi-exclamation-triangle"
                    viewBox="0 0 16 16"
                  >
                    <path
                      d="M7.938 2.016A.13.13 0 0 1 8.002 2a.13.13 0 0 1 .063.016.146.146 0 0 1 .054.057l6.857 11.667c.036.06.035.124.002.183a.163.163 0 0 1-.054.06.116.116 0 0 1-.066.017H1.146a.115.115 0 0 1-.066-.017.163.163 0 0 1-.054-.06.176.176 0 0 1 .002-.183L7.884 2.073a.147.147 0 0 1 .054-.057zm1.044-.45a1.13 1.13 0 0 0-1.96 0L.165 13.233c-.457.778.091 1.767.98 1.767h13.713c.889 0 1.438-.99.98-1.767L8.982 1.566z"
                    />
                    <path
                      d="M7.002 12a1 1 0 1 1 2 0 1 1 0 0 1-2 0zM7.1 5.995a.905.905 0 1 1 1.8 0l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 5.995z"
                    />
                  </svg>
                </div>
                <div style="text-align: start" class="col-8">
                  {{ selectitem.nom }} <br />
                  {{ selectitem.prenom }}
                </div>
              </!--div-->
              <div style="text-align: center; color: green" v-if="exportation">
                <h5>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="50"
                    height="50"
                    fill="currentColor"
                    class="bi bi-check2-circle"
                    viewBox="0 0 16 16"
                  >
                    <path
                      d="M2.5 8a5.5 5.5 0 0 1 8.25-4.764.5.5 0 0 0 .5-.866A6.5 6.5 0 1 0 14.5 8a.5.5 0 0 0-1 0 5.5 5.5 0 1 1-11 0z"
                    />
                    <path
                      d="M15.354 3.354a.5.5 0 0 0-.708-.708L8 9.293 5.354 6.646a.5.5 0 1 0-.708.708l3 3a.5.5 0 0 0 .708 0l7-7z"
                    />
                  </svg>
                  Succès de l'opération
                </h5>
              </div>
            </div>
            <div v-if="!exportation" class="modal-footer">
              <button
                @click="generatePDF()"
                type="button"
                class="btn btn-danger"
              >
                Exporter
              </button>
              <button
                @click="modalExportation = false"
                type="button"
                class="btn btnmodal"
                data-bs-dismiss="modal"
              >
                Annuler
              </button>
            </div>
          </div>
        </div>
      </div>
      <!-- Modal Exportation -->
      <div
        class="modal fade"
        id="staticBackdrop2"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabindex="-1"
        aria-labelledby="staticBackdropLabel"
        aria-hidden="true"
      >
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="modal-header bg-primary">
              <h5 class="modal-title" id="staticBackdropLabel">Exportation</h5>
              <svg
                type="button"
                data-bs-dismiss="modal"
                aria-label="Close"
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="20"
                fill="currentColor"
                class="bi bi-x"
                viewBox="0 0 16 16"
              >
                <path
                  d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"
                />
              </svg>
            </div>
            <div class="modal-body modaltext">
              <!--div v-if="supprimer == false" class="row">
                <div style="text-align: center" class="col">
                  <svg
                    style="color: red"
                    xmlns="http://www.w3.org/2000/svg"
                    width="50"
                    height="50"
                    fill="currentColor"
                    class="bi bi-exclamation-triangle"
                    viewBox="0 0 16 16"
                  >
                    <path
                      d="M7.938 2.016A.13.13 0 0 1 8.002 2a.13.13 0 0 1 .063.016.146.146 0 0 1 .054.057l6.857 11.667c.036.06.035.124.002.183a.163.163 0 0 1-.054.06.116.116 0 0 1-.066.017H1.146a.115.115 0 0 1-.066-.017.163.163 0 0 1-.054-.06.176.176 0 0 1 .002-.183L7.884 2.073a.147.147 0 0 1 .054-.057zm1.044-.45a1.13 1.13 0 0 0-1.96 0L.165 13.233c-.457.778.091 1.767.98 1.767h13.713c.889 0 1.438-.99.98-1.767L8.982 1.566z"
                    />
                    <path
                      d="M7.002 12a1 1 0 1 1 2 0 1 1 0 0 1-2 0zM7.1 5.995a.905.905 0 1 1 1.8 0l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 5.995z"
                    />
                  </svg>
                </div>
                <div style="text-align: start" class="col-8">
                  {{ selectitem.nom }} <br />
                  {{ selectitem.prenom }}
                </div>
              </!--div-->
              <div class="text-center" v-if="exportationVCF">
                <h6 class="text-danger">
                  Vous pouvez exporter qu'un contact à la fois.
                </h6>
              </div>
              <div class="text-center" v-if="pasDeTelephone">
                <h6 class="text-danger">
                  Ce membre n'a pas de numéro de téléphone.
                </h6>
              </div>
              <div style="text-align: center; color: green" v-if="exportation">
                <h5>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="50"
                    height="50"
                    fill="currentColor"
                    class="bi bi-check2-circle"
                    viewBox="0 0 16 16"
                  >
                    <path
                      d="M2.5 8a5.5 5.5 0 0 1 8.25-4.764.5.5 0 0 0 .5-.866A6.5 6.5 0 1 0 14.5 8a.5.5 0 0 0-1 0 5.5 5.5 0 1 1-11 0z"
                    />
                    <path
                      d="M15.354 3.354a.5.5 0 0 0-.708-.708L8 9.293 5.354 6.646a.5.5 0 1 0-.708.708l3 3a.5.5 0 0 0 .708 0l7-7z"
                    />
                  </svg>
                  Succès de l'opération
                </h5>
              </div>
            </div>
            <div v-if="!exportation" class="modal-footer">
              <button
                data-bs-toggle="modal"
                data-bs-target="#staticBackdrop3"
                type="button"
                class="btn btn-danger"
                data-bs-dismiss="modal"
              >
                Listes des Membres
              </button>
              <button
                @click="generateMail()"
                type="button"
                class="btn btn-danger"
              >
                Listes des mails
              </button>
              <!--button
                @click="generateVCF()"
                type="button"
                class="btn btn-danger"
              >
                En VCF
              </!--button>
              <button--
                @click="modalExportation = false"
                type="button"
                class="btn btnmodal"
                data-bs-dismiss="modal"
              >
                Annuler
              </button-->
            </div>
          </div>
        </div>
      </div>
      <!-- Modal confirmation de suppression -->
      <div
        class="modal fade"
        id="staticBackdrop1"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabindex="-1"
        aria-labelledby="staticBackdropLabel"
        aria-hidden="true"
      >
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="modal-header bg-danger">
              <h5 class="modal-title" id="staticBackdropLabel">
                ATTENTION SUPPRESSION
              </h5>
              <svg
                type="button"
                data-bs-dismiss="modal"
                aria-label="Close"
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="20"
                fill="currentColor"
                class="bi bi-x"
                viewBox="0 0 16 16"
              >
                <path
                  d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"
                />
              </svg>
            </div>
            <div class="modal-body modaltext">
              <div v-if="supprimer == false" class="row">
                <div style="text-align: center" class="col">
                  <svg
                    style="color: red"
                    xmlns="http://www.w3.org/2000/svg"
                    width="50"
                    height="50"
                    fill="currentColor"
                    class="bi bi-exclamation-triangle"
                    viewBox="0 0 16 16"
                  >
                    <path
                      d="M7.938 2.016A.13.13 0 0 1 8.002 2a.13.13 0 0 1 .063.016.146.146 0 0 1 .054.057l6.857 11.667c.036.06.035.124.002.183a.163.163 0 0 1-.054.06.116.116 0 0 1-.066.017H1.146a.115.115 0 0 1-.066-.017.163.163 0 0 1-.054-.06.176.176 0 0 1 .002-.183L7.884 2.073a.147.147 0 0 1 .054-.057zm1.044-.45a1.13 1.13 0 0 0-1.96 0L.165 13.233c-.457.778.091 1.767.98 1.767h13.713c.889 0 1.438-.99.98-1.767L8.982 1.566z"
                    />
                    <path
                      d="M7.002 12a1 1 0 1 1 2 0 1 1 0 0 1-2 0zM7.1 5.995a.905.905 0 1 1 1.8 0l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 5.995z"
                    />
                  </svg>
                </div>
                <div style="text-align: start" class="col-8">
                  {{ selectitem.nom }} <br />
                  {{ selectitem.prenom }}
                </div>
              </div>
              <div
                style="text-align: center; color: green"
                v-if="supprimer == true"
              >
                <h5>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="50"
                    height="50"
                    fill="currentColor"
                    class="bi bi-check2-circle"
                    viewBox="0 0 16 16"
                  >
                    <path
                      d="M2.5 8a5.5 5.5 0 0 1 8.25-4.764.5.5 0 0 0 .5-.866A6.5 6.5 0 1 0 14.5 8a.5.5 0 0 0-1 0 5.5 5.5 0 1 1-11 0z"
                    />
                    <path
                      d="M15.354 3.354a.5.5 0 0 0-.708-.708L8 9.293 5.354 6.646a.5.5 0 1 0-.708.708l3 3a.5.5 0 0 0 .708 0l7-7z"
                    />
                  </svg>
                  Succès de l'opération
                </h5>
              </div>
            </div>
            <div v-if="supprimer == false" class="modal-footer">
              <button
                @click="del()"
                type="button"
                class="btn btn-danger"
                data-bs-dismiss="modal"
              >
                Confirmer
              </button>
              <button
                type="button"
                class="btn btnmodal"
                data-bs-dismiss="modal"
              >
                Annuler
              </button>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>
<script>
import jsPDF from "jspdf";
import "jspdf-autotable";
import VCard from "vcard-creator";
//var vCardsJS = require('vcards-js');
import axios from "axios";
export var tabElemen = "";
export var choixID = "";
export default {
  name: "liste_organisation",
  data: () => {
    return {
      series: [44, 55, 13, 43, 22],
      chartOptions: {
        chart: {
          width: 380,
          type: "pie",
        },
        labels: ["Team A", "Team B", "Team C", "Team D", "Team E"],
        responsive: [
          {
            breakpoint: 480,
            options: {
              chart: {
                width: 200,
              },
              legend: {
                position: "bottom",
              },
            },
          },
        ],
      },
      nom: "",
      prenom: "",
      naissance: "",
      genre: "",
      domaine: "",
      photo: "https://www.rab-tech.com/dev_mode/js/api/",
      profession: "",
      dateAdhesion: "",
      categorie: "",
      fonction: "",
      bureau: "",
      matricule: "",
      ville: "",
      localite: "",
      zone: "",
      telephone: "",
      mail: "",
      whatsapp: "",
      facebook: "",
      instagram: "",
      skype: "",
      personnalite: "",
      modification: "1",
      organisation: "",
      fonctionOrganisation: "",
      date_enregistrement: "",
      Tel: "tel:",
      Mail: "mailto:",
      Lienw: " https://wa.me/*",
      Lien: "",
      vide: [],
      h1: "MEMBRES",
      routeModif: "",
      routeNew: "",
      mod: false,
      Etatdeschamps: "",
      reponx: [],
      selectitem: "",
      selectitem2: "",
      affiche: false,
      id_membre: "",
      chargement: true,
      RAS: "",
      search: "",
      filtreur: "nom",
      supprimer: false,
      reponx_code: "",
      msg: "",
      erreur: false,
      msg2: "",
      echec: false,
      noresult: false,
      activation: "x",
      elementNomOrganisation: "",
      elementNom: "",
      elementPrenom: "",
      elementGenre: "",
      elementDomaine: "",
      elementProfession: "",
      elementFonction: "",
      elementBureau: "",
      elementMatricule: "",
      elementVille: "",
      elementLocalite: "",
      elementZone: "",
      elementTelephone: "",
      elementMail: "",
      elementWhatsapp: "",
      elementAdresse: "",
      elementPays: "",
      typeCompte: "",
      elementEnAttente: false,
      elementActive: false,
      modalExportation: false,
      exportation: false,
      tabHeadChecked: [],
      tabBodyChecked: [],
      idChecked: false,
      nomChecked: true,
      prenomChecked: true,
      naissanceChecked: false,
      genreChecked: false,
      domaineChecked: false,
      professionChecked: false,
      dateAdhesionChecked: false,
      categorieChecked: false,
      fonctionChecked: false,
      bureauChecked: false,
      matriculeChecked: false,
      paysChecked: false,
      villeChecked: false,
      localiteChecked: false,
      zoneChecked: false,
      telephoneChecked: true,
      mailChecked: true,
      whatsappChecked: false,
      facebookChecked: false,
      linkedinChecked: false,
      skypeChecked: false,
      date_enregistrementChecked: false,
      exportationVCF: false,
      pasDeTelephone: false,
    };
  },
  methods: {
    firstElement: function () {
      this.selectitem = this.reponx[0];
      this.affiche = true;
      this.exporteur(this.selectitem);
    },
    ActiverChamps: function () {
      var champ = document.getElementById("champsD");
      champ.disabled = !champ.disabled;
    },
    recharger() {
      //window.location.reload();
      this.makeGetRequest();
    },
    makeGetRequest: async function () {
      let lien = document.URL;
      let lien2 = lien.split("/").pop();
      if (lien2 !== "0") {
        this.id_membre = lien2;
      }

      const url =
        "https://www.rab-tech.com/dev_mode/js/api/get_all_membres.php?id=" +
        this.id_membre;
      axios
        .get(url)
        .then((response) => {
          this.reponx_code = response.data.code;
          this.reponx = response.data.membres;
        })
        .catch((error) => {
          console.log(error);
          this.echec = true;
        })
        .finally(() => {
          if (this.reponx_code == 200) {
            this.chargement = false;
            this.firstElement();
            this.echec = false;
          }
          if (this.reponx_code == 300) {
            this.chargement = false;
            this.echec = false;
            this.noresult = true;
          }
        });
      this.choix = "";
      this.choix1 = "";
      this.choix2 = "";
    },
    recH: async function () {
      this.noresult = false;
      this.chargement = true;

      const url =
        "https://www.rab-tech.com/dev_mode/js/api/get_all_membres.php?theme=" +
        this.search;
      const res = await axios.get(url);
      this.reponx = res.data.membres;
      this.reponx_code = res.data.code;
      if (this.reponx_code == 200) {
        this.chargement = false;
        this.firstElement();
        this.echec = false;
      }
      if (this.reponx_code == 300) {
        this.chargement = false;
        this.echec = false;
        this.noresult = true;
      }
    },
    exporteurID(selection, chemin) {
      //choixID=selection
      if (chemin == "organisation") {
        let vm = this;
        vm.$router.push({ path: `/Organisation-detail/${selection} ` });
      }
      if (chemin == "formation") {
        let vm = this;
        vm.$router.push({ path: `/Formation-detail/${selection} ` });
      }
      if (chemin == "event") {
        let vm = this;
        vm.$router.push({ path: `/Evenement-detail/${selection} ` });
      }
    },
    exporteur(selection) {
      tabElemen = selection;
    },
    del: async function () {
      this.chargement = true;
      this.erreur = false;
      const SUP = new FormData();
      SUP.set("id", this.selectitem.id);
      axios
        .post("https://www.rab-tech.com/dev_mode/js/api/delete_membre.php", SUP)
        .then((response) => {
          console.log(response);
          this.reponx_code = response.data.code;
        })
        .catch((error) => {
          console.log(error);
          this.msg = "Echec de la supression. Veillez réessayer svp !";
          this.erreur = true;
          this.chargement = false;
        })
        .finally(() => {
          if (this.reponx_code == "201") {
            this.supprimer = true;
            this.chargement = false;
            this.makeGetRequest();
            this.supprimer = false;
            this.msg2 = "Le membre à bien été supprimé.";
            setTimeout(() => {
              this.msg2 = "";
            }, 3500);
          } else {
            this.msg = "Echec de la supression. Veillez réessayer svp !";
            this.erreur = true;
            this.chargement = false;
            setTimeout(() => {
              this.msg = "";
            }, 3500);
          }
        });
    },
    generatePDF() {
      //alert("pdf");

      if (this.idChecked) {
        this.tabHeadChecked.push({ content: "ID", colSpan: 2 });
      }
      if (this.matriculeChecked) {
        this.tabHeadChecked.push({ content: "MATRICULE", colSpan: 2 });
      }
      if (this.nomChecked) {
        this.tabHeadChecked.push({ content: "NOM", colSpan: 2 });
      }
      if (this.prenomChecked) {
        this.tabHeadChecked.push({ content: "PRENOM", colSpan: 2 });
      }
      if (this.genreChecked) {
        this.tabHeadChecked.push({ content: "GENRE", colSpan: 2 });
      }
      if (this.naissanceChecked) {
        this.tabHeadChecked.push({ content: "D. NAISSANCE", colSpan: 2 });
      }
      if (this.fonctionChecked) {
        this.tabHeadChecked.push({ content: "FONCTION", colSpan: 2 });
      }
      if (this.professionChecked) {
        this.tabHeadChecked.push({ content: "PROFESSION", colSpan: 2 });
      }
      if (this.domaineChecked) {
        this.tabHeadChecked.push({ content: "DOMAINE", colSpan: 2 });
      }
      if (this.bureauChecked) {
        this.tabHeadChecked.push({ content: "BUREAU", colSpan: 2 });
      }
      if (this.categorieChecked) {
        this.tabHeadChecked.push({ content: "CATEGORIE", colSpan: 2 });
      }
      if (this.dateAdhesionChecked) {
        this.tabHeadChecked.push({ content: "D. ADHESION", colSpan: 2 });
      }
      if (this.adresseChecked) {
        this.tabHeadChecked.push({ content: "ADRESSE", colSpan: 2 });
      }
      if (this.paysChecked) {
        this.tabHeadChecked.push({ content: "PAYS", colSpan: 2 });
      }
      if (this.villeChecked) {
        this.tabHeadChecked.push({ content: "VILLE", colSpan: 2 });
      }
      if (this.localiteChecked) {
        this.tabHeadChecked.push({ content: "LOCALITE", colSpan: 2 });
      }
      if (this.zoneChecked) {
        this.tabHeadChecked.push({ content: "ZONE", colSpan: 2 });
      }
      if (this.telephoneChecked) {
        this.tabHeadChecked.push({ content: "TELEPHONE", colSpan: 2 });
      }
      if (this.whatsappChecked) {
        this.tabHeadChecked.push({ content: "WHATSAPP", colSpan: 2 });
      }
      if (this.mailChecked) {
        this.tabHeadChecked.push({ content: "MAIL", colSpan: 2 });
      }
      if (this.facebookChecked) {
        this.tabHeadChecked.push({ content: "FACEBOOK", colSpan: 2 });
      }
      if (this.linkedinChecked) {
        this.tabHeadChecked.push({ content: "LINKEDIN", colSpan: 2 });
      }
      if (this.skypeChecked) {
        this.tabHeadChecked.push({ content: "SKYPE", colSpan: 2 });
      }
      if (this.date_enregistrementChecked) {
        this.tabHeadChecked.push({ content: "D. ENREGISTREMENT", colSpan: 2 });
      }
      //let Color = "black";
      if (this.tabHeadChecked.length > 6) {
        var doc = new jsPDF("l", "mm", "a1");
      } else {
        doc = new jsPDF("l", "mm", "a4");
      }
      doc.autoTable({
        head: [this.tabHeadChecked],
        //head: [[{ content: 'Id', colSpan: 2 }, { content: 'Nom', colSpan: 2 }, { content: 'Prénom', colSpan: 2 }]],
        //theme :  'grid'    ,
        // columnStyles: { 2: { halign: 'center' } },
        //styles : { lineColor: Color = 2, lineWidth: 2   } ,
      });
      for (let i = 0; i < this.filteredMembre.length; i++) {
        const element = this.filteredMembre[i];
        this.tabBodyChecked = [];
        if (this.idChecked) {
          this.tabBodyChecked.push({
            content: element.id,
            colSpan: 2,
            styles: { halign: "left" },
          });
        }
        if (this.matriculeChecked) {
          this.tabBodyChecked.push({
            content: element.matricule,
            colSpan: 2,
            styles: { halign: "left" },
          });
        }
        if (this.nomChecked) {
          this.tabBodyChecked.push({
            content: element.nom,
            colSpan: 2,
            styles: { halign: "left" },
          });
        }
        if (this.prenomChecked) {
          this.tabBodyChecked.push({
            content: element.prenom,
            colSpan: 2,
            styles: { halign: "left" },
          });
        }
        if (this.genreChecked) {
          this.tabBodyChecked.push({
            content: element.genre,
            colSpan: 2,
            styles: { halign: "left" },
          });
        }
        if (this.naissanceChecked) {
          this.tabBodyChecked.push({
            content: element.date_naissance,
            colSpan: 2,
            styles: { halign: "left" },
          });
        }
        if (this.fonctionChecked) {
          this.tabBodyChecked.push({
            content: element.fonction,
            colSpan: 2,
            styles: { halign: "left" },
          });
        }
        if (this.professionChecked) {
          this.tabBodyChecked.push({
            content: element.profession,
            colSpan: 2,
            styles: { halign: "left" },
          });
        }
        if (this.domaineChecked) {
          this.tabBodyChecked.push({
            content: element.domaine_d_intervention,
            colSpan: 2,
            styles: { halign: "left" },
          });
        }
        if (this.bureauChecked) {
          this.tabBodyChecked.push({
            content: element.bureau,
            colSpan: 2,
            styles: { halign: "left" },
          });
        }
        if (this.categorieChecked) {
          this.tabBodyChecked.push({
            content: element.categorie,
            colSpan: 2,
            styles: { halign: "left" },
          });
        }
        if (this.dateAdhesionChecked) {
          this.tabBodyChecked.push({
            content: element.date_adhesion,
            colSpan: 2,
            styles: { halign: "left" },
          });
        }
        if (this.adresseChecked) {
          this.tabBodyChecked.push({
            content: element.adresse,
            colSpan: 2,
            styles: { halign: "left" },
          });
        }
        if (this.paysChecked) {
          this.tabBodyChecked.push({
            content: element.pays,
            colSpan: 2,
            styles: { halign: "left" },
          });
        }
        if (this.villeChecked) {
          this.tabBodyChecked.push({
            content: element.ville,
            colSpan: 2,
            styles: { halign: "left" },
          });
        }
        if (this.localiteChecked) {
          this.tabBodyChecked.push({
            content: element.localite,
            colSpan: 2,
            styles: { halign: "left" },
          });
        }
        if (this.zoneChecked) {
          this.tabBodyChecked.push({
            content: element.zone,
            colSpan: 2,
            styles: { halign: "left" },
          });
        }
        if (this.telephoneChecked) {
          this.tabBodyChecked.push({
            content: element.telephone,
            colSpan: 2,
            styles: { halign: "left" },
          });
        }
        if (this.whatsappChecked) {
          this.tabBodyChecked.push({
            content: element.whatsapp,
            colSpan: 2,
            styles: { halign: "left" },
          });
        }
        if (this.mailChecked) {
          this.tabBodyChecked.push({
            content: element.mail,
            colSpan: 2,
            styles: { halign: "left" },
          });
        }
        if (this.facebookChecked) {
          this.tabBodyChecked.push({
            content: element.facebook,
            colSpan: 2,
            styles: { halign: "left" },
          });
        }
        if (this.linkedinChecked) {
          this.tabBodyChecked.push({
            content: element.linkedin,
            colSpan: 2,
            styles: { halign: "left" },
          });
        }
        if (this.skypeChecked) {
          this.tabBodyChecked.push({
            content: element.skype,
            colSpan: 2,
            styles: { halign: "left" },
          });
        }
        if (this.date_enregistrementChecked) {
          this.tabBodyChecked.push({
            content: element.date_enregistrement,
            colSpan: 2,
            styles: { halign: "left" },
          });
        }
        //const elementID = this.filteredMembre[i].id;
        //const elementNOM = this.filteredMembre[i].nom;
        //const elementPRENOM = this.filteredMembre[i].prenom;
        doc.autoTable({
          body: [
            this.tabBodyChecked,
            //[{ content: element.id, colSpan: 2, rowSpan: 1, styles: { halign: 'left' } },
            //{ content: element.nom, colSpan: 2, rowSpan: 1, styles: { halign: 'left' } },
            //{ content: element.prenom, colSpan: 2, rowSpan: 1, styles: { halign: 'left' } }, ],
            //[element.id, element.nom, element.prenom]
            // ...
          ],
        });
      }
      /*for (let val of this.filteredMembre) {
      doc.autoTable({
      head: [['Id', 'Nom', 'Prénom']],
      body: [
        [val.id, val.nom, val.prenom]
        // ...
      ],
    })
    }*/
      /*doc.autoTable({
      head: [[
        {content: 'Name', colSpan: 2}, {content: 'Email', colSpan: 2}, {content: 'Country', colSpan: 2}]],
      body: [
        [{ content: 'David', colSpan: 1, rowSpan: 1, styles: { halign: 'left' } },
        { content: 'david@example.com', colSpan: 1, rowSpan: 1, styles: { halign: 'left' } },
        { content: 'Sweden', colSpan: 1, rowSpan: 1, styles: { halign: 'left' } }],
        [{ content: 'Castille', colSpan: 1, rowSpan: 1, styles: { halign: 'left' } },
        { content: 'castille@example.com', colSpan: 1, rowSpan: 1, styles: { halign: 'left' } },
        { content: 'Spain', colSpan: 1, rowSpan: 1, styles: { halign: 'left' } } ],
        // ...
      ],
    })*/
      doc.save("Lites des Membres.pdf");
      this.exportation = true;
    },
    generateMail() {
      var vraiElement = [];
      for (let i = 0; i < this.filteredMembre.length; i++) {
        const elementTxt = this.filteredMembre[i];
        let mailExport = "";
        // Some variables
        mailExport = elementTxt.mail.replace(/ /g, "");
        if (mailExport) {
          vraiElement.push(
          elementTxt.nom +
            " " +
            elementTxt.prenom +
            " " +
            "<" +
            mailExport +
            ">" +
            "\n"
        );
        }
      }
      var link = document.createElement("a");
      link.href = "data:text/plain;charset=UTF-8," + escape(vraiElement);
      link.download = "Listes des mails.txt";
      link.click();
      this.exportation = true;
    },
    generateVCF() {
      const myVCardVCF = new VCard();
      var elVcf = "";
      if (this.filteredMembre.length == 1) {
        elVcf = this.filteredMembre[0];
        if (!(elVcf.telephone=="")) {
          if (!(elVcf.infos_organisation == null)) {
          var elVcfNomOrganisation = elVcf.infos_organisation.nom;
        } else {
          elVcfNomOrganisation = "";
        }
        myVCardVCF
          // Add personal data
          .addName(elVcf.prenom, elVcf.nom)
          // Add work data
          .addCompany(elVcfNomOrganisation)
          .addJobtitle(elVcf.profession)
          .addRole(elVcf.fonction)
          .addEmail(elVcf.mail)
          .addPhoneNumber(elVcf.telephone, "PREF;WORK")
          .addPhoneNumber(elVcf.whatsapp, "WORK")
          .addAddress(
            "",
            elVcf.bureau,
            elVcf.localite,
            elVcf.ville,
            "",
            "",
            elVcf.pays
          )
          .addURL([elVcf.facebook, elVcf.linkedin, elVcf.skype])
          //.addURL(elVcf.linkedin)
          //.addURL(elVcf.skype);
        var linkVCF = document.createElement("a");
        linkVCF.href = "data:text/plain;charset=UTF-8," + escape(myVCardVCF);
        linkVCF.download = "Contact de " + elVcf.nom + ".vcf";
        linkVCF.click();
        this.exportation = true;
        }else {
          this.pasDeTelephone= true;
        }
      } else {
        this.exportationVCF = true;
      }
    },
    generateVCFFaux(myVCard) {
      // Define a new vCard
      //const myVCard = new VCard()
      var vraiElement = "";
      for (let i = 0; i < this.filteredMembre.length; i++) {
        myVCard[i] = new VCard();
        const elementVcf = this.filteredMembre[i];
        // Some variables
        const lastname = elementVcf.nom;
        const firstname = elementVcf.prenom;
        const additional = "";
        const prefix = "";
        const suffix = "";

        myVCard[i]
          // Add personal data
          .addName(lastname, firstname, additional, prefix, suffix)
          // Add work data
          .addCompany(elementVcf.bureau)
          .addJobtitle(elementVcf.profession)
          .addRole(elementVcf.fonction)
          .addEmail(elementVcf.mail)
          .addPhoneNumber(elementVcf.telephone, "PREF;WORK")
          .addPhoneNumber(elementVcf.whatsapp, "WORK")
          .addAddress(
            null,
            null,
            elementVcf.localite,
            elementVcf.ville,
            null,
            null,
            elementVcf.pays
          )
          .addURL(elementVcf.facebook);

        console.log(myVCard[i].toString());
        vraiElement += myVCard[i].toString() + "\n";
        /*var link = document.createElement('a');
    link.href = 'data:text/plain;charset=UTF-8,' + escape(myVCard);
    link.download = 'output'+[i]+'.vcf';
    link.click();*/
      }
      return alert(vraiElement.toString());
      /*var express = require('express');
      var router = express.Router();
      
      module.exports = function (app) {
        app.use('/', router);
      };
      
      router.get('/', function (req, res, next) {*/

      /*var vCardsJS = require('vcards-js');
      //alert("vcf");
      var vCard = vCardsJS();
 
      //set properties
      vCard.firstName = 'Kpodonou';
      vCard.middleName = 'A';
      vCard.lastName = 'Kévin';
      vCard.organization = 'RAB TECH';
      //vCard.photo.attachFromUrl('https://avatars2.githubusercontent.com/u/5659221?v=3&s=460', 'JPEG');
      vCard.workPhone = '62703933';
      //vCard.birthday = new Date(1985, 0, 1);
      vCard.title = 'Front-end Developer';
      //vCard.url = 'https://github.com/enesser';
      //vCard.note = 'Notes on Eric';
      
      //save to file
      //vCard.saveToFile('../../kevin-KPODONOU.vcf');
      
      //get as formatted string
      console.log(vCard.getFormattedString());*/
      //send the response
      /* //set content-type and disposition including desired filename
      res.set('Content-Type', 'text/vcard; name="kévin-KPODONOU.vcf"');
      res.set('Content-Disposition', 'inline; filename="kévin-KPODONOU.vcf"');
     res.send(vCard.getFormattedString());
      });*/
      //let text = "lllkhuygtgvcggveych";
      /*var link = document.createElement('a');
    link.href = 'data:text/plain;charset=UTF-8,' + escape(myVCard);
    link.download = 'output.vcf';
    link.click();*/
    },
    rechercher: function () {
      return this.reponx.filter((item) => {
        item.nom.toLowerCase().includes(this.search.toLowerCase());
        this.elementNom = item.nom
          .toLowerCase()
          .includes(this.search.toLowerCase());
        if (this.elementActive == true) {
          return item.is_valide.toLowerCase().includes("1");
        } else {
          if (this.elementEnAttente == true) {
            return item.is_valide.toLowerCase().includes("0");
          } else {
            if (this.elementNom) {
              return this.elementNom;
            } else {
              this.elementPrenom = item.prenom
                .toLowerCase()
                .includes(this.search.toLowerCase());
              this.elementDomaine = item.domaine_d_intervention
                .toLowerCase()
                .includes(this.search.toLowerCase());
              this.elementProfession = item.profession
                .toLowerCase()
                .includes(this.search.toLowerCase());
              this.elementAdresse = item.adresse
                .toLowerCase()
                .includes(this.search.toLowerCase());
              this.elementBureau = item.bureau
                .toLowerCase()
                .includes(this.search.toLowerCase());
              this.elementFonction = item.fonction
                .toLowerCase()
                .includes(this.search.toLowerCase());
              this.elementLocalite = item.localite
                .toLowerCase()
                .includes(this.search.toLowerCase());
              this.elementZone = item.zone
                .toLowerCase()
                .includes(this.search.toLowerCase());
              this.elementWhatsapp = item.whatsapp
                .toLowerCase()
                .includes(this.search.toLowerCase());
              this.elementTelephone = item.telephone
                .toLowerCase()
                .includes(this.search.toLowerCase());
              this.elementMail = item.mail
                .toLowerCase()
                .includes(this.search.toLowerCase());
              this.elementVille = item.ville
                .toLowerCase()
                .includes(this.search.toLowerCase());
              this.elementPays = item.pays
                .toLowerCase()
                .includes(this.search.toLowerCase());
              this.elementGenre = item.genre
                .toLowerCase()
                .includes(this.search.toLowerCase());
              this.elementMatricule = item.matricule
                .toLowerCase()
                .includes(this.search.toLowerCase());
              if (!(item.infos_organisation === null)) {
                this.elementNomOrganisation = item.infos_organisation.nom
                  .toLowerCase()
                  .includes(this.search.toLowerCase());
              }

              if (this.elementPrenom) {
                return this.elementPrenom;
              }
              if (this.elementTelephone) {
                return this.elementTelephone;
              }
              if (this.elementWhatsapp) {
                return this.elementWhatsapp;
              }
              if (this.elementDomaine) {
                return this.elementDomaine;
              }
              if (this.elementAdresse) {
                return this.elementAdresse;
              }
              if (this.elementLocalite) {
                return this.elementLocalite;
              }
              if (this.elementMail) {
                return this.elementMail;
              }
              if (this.elementVille) {
                return this.elementVille;
              }
              if (this.elementPays) {
                return this.elementPays;
              }
              if (this.elementFonction) {
                return this.elementFonction;
              }
              if (this.elementProfession) {
                return this.elementProfession;
              }
              if (this.elementBureau) {
                return this.elementBureau;
              }
              if (this.elementGenre) {
                return this.elementGenre;
              }
              if (this.elementZone) {
                return this.elementZone;
              }
              if (this.elementMatricule) {
                return this.elementMatricule;
              }
              if (this.elementNomOrganisation) {
                return this.elementNomOrganisation;
              }
            }
          }
        }
      });
    },
  },
  created() {
    this.makeGetRequest();
    let elementUser = JSON.parse(sessionStorage.getItem("authUser"));
    this.typeCompte = elementUser.type;
  },
  computed: {
    filteredMembre() {
      return this.rechercher();
    },
    monFiltre: function () {
      return this.reponx.filter((item) => {
        if (this.filtreur == "nom") {
          return item.nom.toLowerCase().match(this.search.toLowerCase());
        }
        if (this.filtreur == "prenom") {
          return item.prenom.toLowerCase().match(this.search.toLowerCase());
        }
        if (this.filtreur == "telephone") {
          return item.telephone.match(this.search);
        }
        if (this.filtreur == "localite") {
          return item.localite.toLowerCase().match(this.search.toLowerCase());
        }
        if (this.filtreur == "pays") {
          return item.pays.toLowerCase().match(this.search.toLowerCase());
        }
        if (this.filtreur == "ville") {
          return item.ville.toLowerCase().match(this.search.toLowerCase());
        }
      });
    },
  },
};
</script>
